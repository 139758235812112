



























































































































































.user-withdraw {
  max-width: 405px;
  width: 100%;
  margin: auto;
  .title {
    font-weight: 600;
    font-size: clamp(15px, 2vw, 17px);
    color: #2f394e;
    text-align: center;
    padding: 0 0 15px;
    border-bottom: 3px solid rgba($color: #000000, $alpha: 0.2);
    margin-bottom: 15px;
  }
  .form-group {
    label {
      font-weight: 600;
      color: #6c757d;
      font-size: clamp(14px, 2vw, 16px);
      margin-bottom: 0px;
    }
    select,
    input {
      border: 0;
      border-radius: 0;
      border-bottom: 2px solid #6c757d;
      height: 40px;
      font-size: clamp(15px, 1vw, 16.2px);
      font-weight: 600;
      &:focus {
        box-shadow: none;
        border-bottom: 2px solid #097501;
      }
      &::placeholder {
        font-weight: 500;
        font-size: clamp(13px, 1vw, 15px);
      }
    }
  }
}
